<template>
  <div class="content">
    <div class="refundTitle">退票申请</div>
    <div class="refundTips" @click="lookRefundTips">
      <p>查看退改政策</p>
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-05/22/yuelvhuit325zNszon1609856971.png"
        alt=""
      >
    </div>
    <div class="refundPassengersList">
      <p class="refundPassengersListTitle">1.选择退票的乘客</p>
      <div class="PassengersListWrap">
        <div
          v-for="(item,index) in PassengersList"
          :key="index"
          :class="item.orderStatus !==5 ? 'PassengersListNo' :'PassengersList'"
        >
          <div class="selImgWrap">
            <img
              v-if="item.orderStatus===5"
              :src="item.isSel === true ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
              class="selImg"
              @click="selPerson(item)"
            >
          </div>
          <div class="personInfo">
            <div class="name">
              <p>{{ item.name }}</p>
              <p v-if="item.orderStatus !== 5" class="StatusText">{{ item.orderStatusText }}</p>
            </div>
            <div class="cardAndMobile">
              <p class="cardId">{{ item.idTypeText }}<span>{{ item.idCard }}</span></p>
              <p class="mobile">手机号<span>{{ item.phone }}</span></p>
            </div>
          </div>
          <div class="van-hairline--bottom" />
        </div>
      </div>
    </div>
    <div class="refundTravel">
      <p class="refundPassengersListTitle">2.选择退票的行程</p>
      <div class="refundTravelList">
        <div v-for="(item,index) in refundTravelList" :key="index" class="refundTravelListWrap">
          <div class="selImg">
            <img
              :src="item.isSel ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
              class="selImg"
              @click="selTravel(item)"
            >
          </div>
          <div class="refundTipsTravel">
            <div class="TravelDetails">
              <p>{{ item.date }}</p>
              <div class="TravelDetailss">
                <p>{{ item.startCity }}</p>
                <img
                  src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
                  alt=""
                >
                <p>{{ item.endCity }}</p>
              </div>
            </div>
            <div class="TravelTips" @click="lookRefundTips">
              <p>退改签说明</p><img
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-09/14/yuelvhuil3iw1dRX3D1610173345.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="refundDetails">
      <div class="AdultInfoDetail">
        <div class="AdultInfoPrice">
          <p>需退价钱</p>
          <div style="width: 206px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
          <p>¥{{ adultPrice }}/{{ selPersonList.length }}人</p>
        </div>
        <div class="AdultInfoTicketPrice">
          <p>机票价</p>
          <p>¥{{ adultTotalPrice }}</p>
        </div>
        <div class="AdultInfoFuelCostsPrice">
          <p>基建燃油</p>
          <p>¥{{ adultTotalFuelCosts }}</p>
        </div>
        <!--                <div class="AdultInfoTaxPrice">-->
        <!--                    <p>燃油</p>-->
        <!--                    <p>¥{{adultTotalTax}}</p>-->
        <!--                </div>-->
        <div class="AdultInfoPrice">
          <p>退票费</p>
          <div style="width: 206px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
          <p>¥{{ refundPrice }}/{{ selPersonList.length }}人</p>
        </div>
        <div class="TotalPrice">
          <p>总计</p>
          <p>退 ¥{{ adultTotalTax }}/{{ selPersonList.length }}</p>
        </div>
      </div>
    </div>
    <div class="refundNextStep">
      <div class="ProjectedRefundPrice">预计退款<span>¥{{ adultTotalTax }}</span></div>
      <div class="goNext" @click="goNext">下一步</div>
    </div>

    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <van-tabs v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ adtPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订
              儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="chdBaggage !== undefined" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p style="width: 100%">{{ isChange }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet,
  Calendar,
  Icon,
  Loading,
  Sidebar,
  SidebarItem,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Icon)
  .use(Calendar)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)

import {
  getRefundDetail,
  getProductDes,
  getRefundChangeFees
} from '@/services/comeOn.js'

export default {
  name: 'TicketRefund',
  data() {
    return {
      orderNo: '', // 订单号
      PassengersList: [], // 乘客列表
      selPersonList: [], // 选中乘客列表
      refundTravelList: [], // 退改签行程列表
      selRefundTravelList: [], // 选中的行程列表
      DisplayActive: 0,
      showDisplayActive: false, // 退改签说明
      startAddress: '', // 开始行程
      endAddress: '', // 结束行程
      adtPrice: 0, // 成人票价
      CabinName: '', // 舱位
      chdPrice: 0, // 儿童票价
      consign: 0, // 托运行李重量
      portable: 0, // 免费手提行李重量
      volume: 0, // 行李体积
      chdBaggage: 0, // 儿童行李标准
      adtRefundFeeList: [], // 成人退票收费list
      chdRefundFeeList: [], // 儿童退票收费list
      adtConcessionFeeList: [], // 成人同舱改期收费规则
      chdConcessionFeeList: [], // 儿童同舱改期收费规则
      isChange: '', // 改签说明
      adultPrice: 0, // 退款价格/人
      adultTotalPrice: 0, // 退款机票价/人
      adultTotalFuelCosts: 0, // 退票基建/人
      adultTotalTax: 0, // 退票燃油/人
      refundPrice: 0, // 退票手续费/人
      totalprice: 0// 预计退款总价
    }
  },
  created() {

  },
  mounted() {
    this.orderNo = this.$route.query ? this.$route.query.orderNo : ''// 订单号
    this.getRefundDetails()// get退票详情
  },
  methods: {
    // get退票信息
    getRefundDetails() {
      getRefundDetail({
        orderNo: this.orderNo
      }).then(res => {
        if (Number(res.code) === 200) {
          this.refundInfo = res.data
          const PassengersList = res.data.person
          PassengersList.forEach(e => {
            e.isSel = false
          })
          this.PassengersList = PassengersList// 乘客列表（根据状态显示是否退票）
          this.refundTravelList = [{
            date: res.data.date,
            startCity: res.data.depCity,
            endCity: res.data.arrCity,
            isSel: false,
            clickNumber: 0,
            logo: res.data.logo,
            airCompanyName: res.data.airCompanyName, // 航空名称
            flightNo: res.data.flightNo, // 航空号码
            depTimeW: res.data.depTimeW, // 周几
            depTime: res.data.depTime, // 出发时间
            arrTime: res.data.arrTime, // 到达时间
            depAirportName: res.data.depAirportName, // 出发航空
            arrAirportName: res.data.arrAirportName// 到达航空
          }]
        } else {
          this.$router.go(-1)
        }
      })
    },
    // 选择乘客
    selPerson(item) {
      this.item = item
      item.isSel = !item.isSel
      if (item.isSel === true) {
        this.selPersonList.push(item)
        if (this.selRefundTravelList.length === 0) {
          return// 没有选择行程
        } else {
          // let ticketIdsObj = {};
          // this.selPersonList.forEach((e,index)=>{
          //     ticketIdsObj['ticketIds[' + index + ']'] = e.ticketId;
          // })
          // this.getRefundFees(item.orderNo,ticketIdsObj);//查询退改手续费
          // this.getRefundFees(item.orderNo,item.ticketId);
          this.selPersonList.forEach(e => {
            this.AmountPrice(e, 0)
          })
        }
      } else {
        this.selPersonList.forEach(e => {
          if (e.ticketId === item.ticketId) {
            this.AmountPrice(e, 1)
            this.selPersonList.splice(e, 1)
          }
        })
      }
    },
    // 选中行程
    selTravel(item) {
      item.clickNumber++// 点击次数
      this.selRefundTravelList = []// 清空选中的行程
      this.refundTravelList.forEach(e => {
        e.isSel = false
      })
      this.selRefundTravelList.push(item)
      if (this.selPersonList.length === 0) {
        // return false;//没有选择乘客
      } else {
        this.AmountPrice(this.item, 0)// 加
      }
      item.isSel = !item.isSel
      if (item.clickNumber > 1) {
        this.AmountPrice(this.item, 1)// 减
      }
    },
    // 选择乘客计算金额
    AmountPrice(e, type) {
      if (type === 0) { // 加
        this.adultPrice = this.adultPrice + e.ticketAmount// 需退价钱
        this.adultTotalPrice = this.adultTotalPrice + e.ticketPrice// 机票价
        this.adultTotalFuelCosts = this.adultTotalFuelCosts + this.refundInfo.taxation// 基建
        // this.adultTotalTax = item.;//燃油
        this.refundPrice = this.refundPrice + e.fee// 退票费
        this.adultTotalTax = this.adultTotalTax + e.refundAmount// 总计
      } else if (type === 1) { // 减
        this.adultPrice = this.adultPrice - e.ticketAmount// 需退价钱
        this.adultTotalPrice = this.adultTotalPrice - e.ticketPrice// 机票价
        this.adultTotalFuelCosts = this.adultTotalFuelCosts - this.refundInfo.taxation// 基建
        // this.adultTotalTax = item.;//燃油
        this.refundPrice = this.refundPrice - e.fee// 退票费
        this.adultTotalTax = this.adultTotalTax - e.refundAmount// 总计
      }
    },
    // 查询退改手续费
    getRefundFees(id, ticketIdsObj) {
      // ticketIdsObj.id = id;
      // ticketIdsObj.applyType = 0;//0自愿1非自愿
      const ticketIds = {
        orderNo: id,
        applyType: 0,
        ticketIds: ticketIdsObj,
        queryType: 0// 0退票手续费查询1改期手续费查询
      }
      getRefundChangeFees(ticketIds).then(res => {
      })
    },
    //    查看退改签说明
    lookRefundTips() {
      this.showNote()// 展示说明
    },
    // 展示说明
    showNote() {
      getProductDes({
        flightInfoId: this.refundInfo.flightInfoId,
        tripType: this.tripType, // 单程OW返程RT
        priceInfoId: this.refundInfo.priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.CabinName = res.data.chd.CabinName// 舱位
          this.adtPrice = res.data.adt.ticketPrice// 成人票价
          this.chdPrice = res.data.chd.ticketPrice// 儿童票价
          this.consign = res.data.adt.luggage.consign// 成人托运行李
          this.portable = res.data.adt.luggage.portable// 成人手提行李
          this.volume = res.data.adt.luggage.volume// 成人手提行李面积
          // 儿童行李标准
          this.chdBaggage = res.data.chd.luggage

          this.adtRefundFeeList = res.data.adt.refundCondition// 成人退票收费list
          this.chdRefundFeeList = res.data.chd.refundCondition// 儿童退票收费标准
          this.adtConcessionFeeList = res.data.adt.rerouteCondition// 成人同舱改期收费规则
          this.chdConcessionFeeList = res.data.chd.rerouteCondition// 儿童同舱改期收费规则
          this.isChange = res.data.chd.transferRule// 改签说明
          this.showDisplayActive = true// 显示产品说明
        }
      })
    },
    // 下一步
    goNext() {
      if (this.selPersonList.length === 0) {
        Toast('请选择乘客')
        return false
      } else if (this.selRefundTravelList.length === 0) {
        Toast('请选择行程')
        return false
      } else {
        // 退改签行程列表
        window.localStorage.setItem('selRefundTravelList', JSON.stringify(this.selRefundTravelList))
        // 退改签乘客
        window.localStorage.setItem('selPersonList', JSON.stringify(this.selPersonList))
        // 退改签乘机人
        this.$router.push({
          path: '/refundConfirmation',
          query: {
            isType: '',
            totalPrice: this.adultTotalTax,
            orderNo: this.orderNo,
            rtFlagText: this.refundInfo.rtFlagText
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  text-align: left;
}

.content {
  position: absolute;

  .refundTitle {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 17px;
    color: #333333;
    background-color: #fff;
  }

  /*提示*/

  .refundTips {
    width: 100%;
    height: 43px;
    background-color: #FCEEEE;
    line-height: 43px;
    font-size: 12px;
    padding: 0 14px;
    display: flex;
    position: relative;

    p {
      color: #E33F44;
    }

    img {
      margin-left: auto;
      width: 7px;
      height: 12px;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 14px;
    }
  }

  /*乘机人*/

  .refundPassengersList, .refundTravel {
    /*margin-top: 31px;*/
    padding: 31px 15px 18px;

    .refundPassengersListTitle {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
    }

    .PassengersListWrap {
      .PassengersList, .PassengersListNo {
        display: flex;
        margin-top: 27px;

        .selImgWrap {
          width: 17px;

          img {
            width: 17px;
            height: 17px;
          }
        }

        .personInfo {
          margin-left: 17px;

          .name {
            font-size: 17px;
            font-weight: 600;
            display: flex;

            .StatusText {
              width: 53px;
              height: 18px;
              font-size: 12px;
              border: 1px solid #999999;
              color: #999999;
              border-radius: 3px;
              margin-left: 13px;
              text-align: center;
              line-height: 18px;
            }
          }

          .cardAndMobile {
            font-size: 14px;

            .cardId, .mobile {
              margin-top: 10px;

              span {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .PassengersList {
      color: #333333;
    }

    .PassengersListNo {
      color: #999999;
    }
  }

  /*退票行程*/

  .refundTravel {
    .refundTravelListWrap {
      display: flex;
      margin-top: 26px;
    }

    /*退票列表*/

    .refundTravelList {
      .selImg {
        width: 17px;
        height: 17px;
      }

      /*退改签提示*/

      .refundTipsTravel {
        margin-left: 27px;

        .TravelDetails {
          font-weight: 600;
          font-size: 17px;
          display: flex;
          color: #000000;

          .TravelDetailss {
            display: flex;
            margin-left: 19px;

            img {
              width: 15px;
              height: 5px;
              margin: 5px 10px;
            }
          }
        }

        .TravelTips {
          display: flex;
          margin-top: 14px;

          p {
            color: #333333;
            font-size: 12px;
          }

          img {
            width: 13px;
            height: 13px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  /*退票明细*/

  .refundDetails {
    padding-bottom: 80px;

    .AdultInfoDetail {
      background: rgb(239, 241, 242);
      padding: 20px 16px;

      .AdultInfoPrice, .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        display: flex;
        color: #333333;

        p:nth-child(1) {
          margin-bottom: 18px;
        }

        p:nth-child(2) {
          margin-left: auto;
        }
      }

      .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        font-size: 12px;

        p:nth-child(2), p:nth-child(3), p:nth-child(4) {
          margin-bottom: 12px;
        }
      }

      .AdultInfoPrice {
        p {
          font-size: 14px;
        }

        p:nth-child(3) {
          margin-left: auto;
        }
      }

      .TotalPrice {
        display: flex;

        p {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(2) {
          margin-left: auto;
          color: #E33F44;
        }
      }
    }
  }

  /*下一步*/

  .refundNextStep {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    text-align: left;
    display: flex;
    padding: 17px 13px;
    background-color: #f8f8f8;

    .ProjectedRefundPrice {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      span {
        color: #E33F44;
        margin-left: 10px;
      }
    }

    .goNext {
      margin-left: auto;
      width: 100px;
      height: 40px;
      background-color: #E33F44;
      text-align: center;
      line-height: 40px;
      font-size: 17px;
      color: #ffffff;
      border-radius: 20px;
    }
  }
}
</style>
